import { Strapi4RequestParams } from "@nuxtjs/strapi/dist/runtime/types/v4";
import type { SearchConfig, TableConfigStrapi } from "~/settings/table/config";
import { ActiveFilter, FilterKeyword } from "~/settings/table/filter/table";

type TypeConvertToNoRel<T, ID extends number | undefined> = {
    [K in keyof T]
    : K extends 'config' ? T[K]
    : K extends 'migration_data' ? T[K]
    : T[K] extends Date | undefined ? Date
    : T[K] extends { data: { attributes: any } } | undefined ? number
    : T[K] extends { data: any[] } | undefined ? number[]
    : T[K] extends {} | undefined ? TypeConvertToNoRel<T[K], undefined>
    : T[K]
    ;
} & (ID extends number ? { id: number } : {});

type NonNullable<T> = Exclude<T, null | undefined>;

export type ReduceStrapiResponse<T extends { [k: string]: any }> = ReduceStrapiResponseInternal<T> & { id: number };

type ReduceStrapiResponseInternal<T extends { [k: string]: any }> = {
    [K in keyof T]
    : K extends 'config' ? T[K]
    : K extends 'migration_data' ? T[K]
    : T[K] extends Date | undefined ? Date
    : T[K] extends { data: { attributes: object, id: number } } | undefined ? ReduceStrapiResponseInternal<NonNullable<T[K]>["data"]["attributes"]> & { id: number }
    : T[K] extends { data: any } | undefined ? (ReduceStrapiResponseInternal<NonNullable<T[K]>["data"][number]["attributes"]> & { id: number })[]
    : T[K] extends Object | undefined ? (ReduceStrapiResponseInternal<NonNullable<T[K]>>)
    : T[K]
    ;
}


function convertToNoRelation<T extends { attributes: Object, id: number }>(data: T): TypeConvertToNoRel<T["attributes"], T["id"]>
function convertToNoRelation<T extends { data: { attributes: Object, id: number } }>(data: T): TypeConvertToNoRel<T["data"]["attributes"], T["data"]["id"]>

function convertToNoRelation<T extends Object, ID extends number | undefined>(data: T, id?: ID): TypeConvertToNoRel<T, ID>

function convertToNoRelation(data: Object | undefined, id?: number): any {
    if (data === undefined) return undefined
    if ("data" in data && typeof data.data == "object" && data.data && "attributes" in data.data) {
        data = data.data;
    }
    if ("attributes" in data && "id" in data) {
        if (typeof data.id === "number") id = data.id;
        if (typeof data.attributes === "object" && data.attributes) data = data.attributes;
    }
    return convertType(data, id)
}

function convertType<T extends object>(data: T, id: number | undefined) {
    let result: any = Array.isArray(data) ? [] : {};
    if (id) result.id = id;

    for (let [k, v] of Object.entries(data)) {
        if (k === 'config' || k === 'migration_data') {
            result[k] = v;
        } else if (v instanceof Date) {
            result[k] = v;
        } else if (v?.data === null || (v?.data?.attributes && v?.data?.id)) {
            result[k] = v.data?.id;
        } else if (v?.data && Array.isArray(v.data)) {
            result[k] = v.data.map((i: any) => { return i.id })
        } else if (v instanceof Object) {
            result[k] = convertToNoRelation(v);
        } else {
            result[k] = v;
        }
    }
    return result;
}

export type ForceDate<T> = {
    [K in keyof T]
    : T[K] extends Date ? Date
    : T[K] extends Date | undefined ? Date | undefined
    : T[K];
};

function force2Date<T extends Object>(data: T): ForceDate<T> {
    return data as unknown as ForceDate<T>;
}


export type ForceRequiredProperty<Type, Key extends keyof Type> = Type & {
    [Property in Key]-?: Type[Property];
};


export type GetRequiredKeys<T> = { [P in keyof T]: T[P] extends { required: any } ? P : never }[keyof T]

type schemaAttributes = {
    kind: string,
    collectionName: string,
    info: {
        singularName: string,
        pluralName: string,
        displayName: string,
        description: string
    },
    options: {
        draftAndPublish: boolean,

    },
    pluginOptions: any,
    attributes: {
        [k: string]: {
            type: string,
            required?: boolean,
            relation?: string,
            target?: string
        }
    }
}


export const convertSchemaToDefaultData = (data: schemaAttributes, defaults?: { [k: string]: any }): any => {
    let result: { [k: string]: string | number | null | any[] | {} } = {};

    for (let [k, v] of Object.entries(data.attributes)) {
        if (defaults && defaults[k]) {
            result[k] = defaults[k];
        } else {
            switch (v.type) {
                case 'string':
                    result[k] = '';
                    break;

                case 'integer':
                    result[k] = 0;
                    break;

                case 'enumeration':
                    result[k] = '';
                    break;

                case 'relation':
                    if (v.relation === 'oneToOne') {
                        result[k] = null;
                    } else if (v.relation === 'oneToMany') {
                        result[k] = []
                    } else {
                        console.error('unknown relation type in convertSchemaToDefaultData: ', v.relation);
                    }
                    break;

                case 'json':
                    result[k] = {};
                    break
                default:
                    console.error('unknown type in convertSchemaToDefaultData:', v.type);
            }
        }
    }
    return result;
}


type Obj = { [key: string]: any };

const arrayToObjectArray = (arr: Obj[]): Obj => {
    return arr.reduce((acc: Obj, obj: Obj) => {
        Object.keys(obj).forEach((key: string) => {
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj[key]);
        });
        return acc;
    }, {});
}


type t = ReturnType<typeof reduceStrapiResponseList>

export const reduceStrapiResponseList = <T extends { data: { attributes: any, id: number }[] }>(data: T, options?: { reduceArray?: boolean }): ReduceStrapiResponse<T["data"][number]["attributes"]>[] => {
    return data.data.map(i => { return { ...reduceStrapiResponseInternal(i.attributes, options), id: i.id } });
}

function reduceStrapiResponse<T extends { data: { attributes: any, id: number } }>(data: T, options?: { reduceArray?: boolean }): ReduceStrapiResponse<T["data"]["attributes"]>
function reduceStrapiResponse<T extends { attributes: any, id: number }>(data: T, options?: { reduceArray?: boolean }): ReduceStrapiResponse<T["attributes"]>

function reduceStrapiResponse<T extends { attributes: any, id: number } | { data: { attributes: any, id: number } }>(data: T, options?: { reduceArray?: boolean }): any {
    if ("data" in data) return { ...reduceStrapiResponseInternal(data.data.attributes, options), id: data.data.id }
    return { ...reduceStrapiResponseInternal(data.attributes, options), id: data.id }
}

const reduceStrapiResponseInternal = <T extends Object>(data: T, options?: { reduceArray?: boolean }): any => {
    if (data === undefined) {
        return undefined
    } else if (data === null) {
        return null;
    } else if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
        return data;

    } else if (Array.isArray(data)) {
        return (data as any[]).map((i): any => {
            return reduceStrapiResponseInternal(i, options);
        }) as any
    } else if (typeof data === 'object' && "attributes" in data && "id" in data) {
        return { id: data.id, ...reduceStrapiResponseInternal(data.attributes as any, options) }
    } else {

        return reduceStrapiResponseInternalValues(data, options);
    }

}

const reduceStrapiResponseInternalValues = <T extends Object>(data: T, options?: { reduceArray?: boolean }) => {
    let result: any = {};

    for (let [k, v] of Object.entries(data)) {
        if (k === 'migration_data' || k === 'custom_data') {
            result[k] = v;
        } else if (v instanceof Date) {
            result[k] = v;
        } else if ((v?.attributes && v?.id)) {
            result[k] = { id: v.id, ...reduceStrapiResponseInternal(v.attributes, options) };
        } else if (v?.data && Array.isArray(v.data) && !v.data.length) {
            result[k] = []
        } else if ((v?.data?.attributes && v?.data?.id)) {
            result[k] = { id: v.data.id, ...reduceStrapiResponseInternal(v.data.attributes, options) };
        } else if (v?.data && Array.isArray(v.data)) {
            if (options?.reduceArray) {
                result[k] = arrayToObjectArray(v.data.map((i: any) => { return { id: i.id, ...reduceStrapiResponseInternal(i.attributes, options) } }))
            } else {
                result[k] = v.data.map((i: any) => { return { id: i.id, ...reduceStrapiResponseInternal(i.attributes, options) } })
            }
        } else if (options?.reduceArray && Array.isArray(v) && v.length > 0 && v[0] instanceof Object && v[0].hasOwnProperty('id')) {
            result[k] = arrayToObjectArray(v.map((i: any) => { return reduceStrapiResponseInternal(i, options) }))

        } else if (v instanceof Object) {
            result[k] = reduceStrapiResponseInternal(v, options);
        } else {
            result[k] = v;
        }
    }
    return result;
}


const string2DeepObject = (str: string | string[], value: any): { [key: string]: any } => {

    if (!Array.isArray(str)) {
        str = str.split(".");
    }
    const key = str.shift() as string;
    if (!str.length) return { [key]: value };
    return { [key]: string2DeepObject(str, value) };
}




export const strapiSearch = (parameter: string, keyword: FilterKeyword, value: string | number | string[]) => {
    if ((keyword === 'in' || keyword === 'notIn' || keyword === 'between') && typeof value === 'string') {
        value = value.split(/[;,]/);
    }
    return string2DeepObject(parameter, { ['$' + keyword]: value })

}

type filterSubType = { [key: string]: any }[]
const combineFilter = (filter: filterSubType, operator: '$or' | '$and'): filterSubType => {
    let keys = [... new Set(filter.map(i => Object.keys(i)).reduce((a, e) => [...a, ...e], []))];
    let r = keys.map((key) => {
        let values = filter.filter((i) => i[key])
        if (values.length === 1) return values[0];
        let x = { [key]: { [operator]: values.map((i) => i[key]) } }
        return x
    })
    return r;
}

type filterArray = [string, FilterKeyword, string | number][]
const filterArray2RestAPI = (filters: filterArray, operator: '$or' | '$and', injectedFilter?: { [key: string]: any }[]) => {
    if (!filters.length) return {};
    const o = operator ?? '$and';
    let r = {
        [o]: combineFilter([...filters.map((item) => {
            return strapiSearch(item[0], item[1], item[2])
        }), ...(injectedFilter || [])], o)
    }
    return r;
}

const filter2RestAPI = (filters: (ActiveFilter)[]): Strapi4RequestParams["filters"] => {
    return {
        $and: filters.map((item) => {
            const value = item.filter.dataType.includes('number') && !isNaN(parseFloat(item.value)) ? parseFloat(item.value) : item.value;
            return strapiSearch(item.column.key, item.filter.gqlQueryKeyword, value)
        })
    }
}

const prepareArray4RestApi = (fields: SearchConfig["fields"], searchText: string) => {

    let filterMulti: { [key: string]: any }[] = [];
    let filter: filterArray = [];
    for (let field of fields) {
        if ("multiSearch" in field && field.multiSearch?.length) {
            if (field.filterIfMatch && !field.filterIfMatch.test(searchText)) continue;
            filterMulti.push(filterArray2RestAPI(field.multiSearch.map((item) => {
                const text = item.stringPrepareFunction ? item.stringPrepareFunction(searchText) : searchText;
                return [item.name, item.keyword, text]
            }), '$and'))

        } else if ("name" in field) {
            const text = field.stringPrepareFunction ? field.stringPrepareFunction(searchText) : searchText;
            filter.push([field.name, field.keyword, text])

        }
    }
    return filterArray2RestAPI(filter, '$or', filterMulti)
}


export const search2RestAPI = (searchText: string, config: SearchConfig): Strapi4RequestParams["filters"] => {
    let fields = config.fields.filter(f => {
        if (f.filterIfMatch) {
            return !!searchText.match(f.filterIfMatch)
        } else {
            return true;
        }
    })
    return prepareArray4RestApi(fields, searchText)
}


const populateDeep = (keys: string[], populate: { [k: string]: any }, depth: number, options?: { addFieldFlat?: boolean }): any => {
    if (options?.addFieldFlat && keys.length) {
        populate.fields = [...new Set([...(populate.fields || []), keys.join('.')])];
    }
    const key = keys.shift();

    if (key) {
        if (!keys.length) {
            populate.fields = [...new Set([...(populate.fields || []), key])];

        } else {
            populate.populate = populate.populate || {};
            populate.populate[key] = populate.populate[key] || { fields: [] };
            populateDeep(keys, populate.populate[key], depth + 1);
        }
    }
}

export const columns2Populate = (config: TableConfigStrapi, populateInput?: { [k: string]: any }, options?: { addFieldFlat?: boolean }) => {
    const populate = populateInput || {};
    config.columns = config.columns.filter(c => c !== undefined)
    for (let column of config.columns) {
        if (column.placeholder) continue;
        populateDeep(column.key.split('.'), populate, 0, options);
    }

    return populate;
}

export const valueArray = (value: any) => {
    if (!Array.isArray(value)) return false;
    for (let v of value) {
        if (v !== null && v !== undefined && !(["string", "number"].includes(typeof v)) && !(v instanceof Date)) {
            return false;
        }
    }
    return true;
}

const reduceArray = <T extends object>(dataInput: T[]) => {
    let data = dataInput;
    let result = data.reduce((previousValue, currentValue) => {
        if (currentValue !== null && currentValue !== undefined) {
            for (let [key, value] of Object.entries(currentValue)) {
                if (key === 'migration_data' || key === 'custom_data') {
                    previousValue[key] = value;
                } else if (valueArray(value)) {
                    previousValue[key] = value;

                } else if (["string", "number"].includes(typeof value) || value instanceof Date || value === null || value === undefined) {
                    previousValue[key] = previousValue[key] || [];
                    previousValue[key].push(value);

                } else if (typeof value === "object") {
                    if (key in previousValue) {
                        let valueData = Array.isArray(value) ? [previousValue[key], ...value] : [previousValue[key], value];
                        let x = reduceArray(valueData)
                        previousValue[key] = x;
                    } else if (Array.isArray(value)) {
                        let x = reduceArray(value);
                        previousValue[key] = x
                    } else {
                        let x = reduceResultArrays(value);
                        previousValue[key] = x
                    }
                }
            }
        }
        return previousValue;
    }, {} as any)

    return result;
}

type objectType = { [k: string]: string | number | Date | any[] | null | { [k: string]: any } }
export const reduceResultArrays = (dataInput: objectType | objectType[]) => {
    let data = dataInput;
    if (Array.isArray(data)) {
        let x = data.map(d => reduceResultArrays(d)) as objectType[];
        return x;
    } else {
        data = reduceResultArraysInternal(data);
    }
    return data;
}

const reduceResultArraysInternal = (dataInput: objectType) => {
    for (let [k, v] of Object.entries(dataInput)) {
        if (k === 'custom_data' || k === 'migration_data') {
            dataInput[k] = v
        } else if (v === null || v === undefined) {
            // No change needed
        } else if (Array.isArray(v)) {
            if (valueArray(v)) {
                // No change needed
            } else {
                dataInput[k] = reduceArray(v)
            }

        } else if (typeof v === 'object' && v instanceof Date === false) {
            dataInput[k] = reduceResultArrays(v);
        }
    }
    return dataInput;
}



export { convertToNoRelation, filter2RestAPI, filterArray2RestAPI, force2Date, string2DeepObject, reduceStrapiResponse };
